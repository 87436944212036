import TomSelect from "tom-select";
import { Loader as GoogleMapsLoader } from "@googlemaps/js-api-loader";

interface WorkHistory {
  company: string | null;
  vessel_name: string | null;
  rank: string | null;
  start_at: string | null;
  end_at: string | null;
  is_current: boolean;
}

interface Candidate {
  id: string | null;
  application_date: string | null;
  avatar: string | null;
  email: string | null;
  ex_crew: boolean;
  full_name: string | null;
  home_phone: string | null;
  initials: string | null;
  manning_agent_initials: string | null;
  manning_agent_name: string | null;
  manning_agent_logo: string | null;
  mobile_phone: string | null;
  rank: string | null;
  readiness_date: string | null;
  source_initials: string | null;
  source_name: string | null;
  source_logo: string | null;
  vessel_id: string | null;
  work_histories: WorkHistory[];
}

interface Location {
  id: string;
  candidates: Candidate[];
  lat: number;
  lon: number;
  heading: number;
  vessel_id: string;
  vessel_initials: string;
  vessel_name: string;
  vessel_photo: string | null;
  vessel_type: string | null;
}

interface LocationOption {
  title: string;
  id: string;
}

function createMarkerElement(
  id: string,
  vessel_name: string,
  heading: number,
  selected: boolean,
) {
  const div = document.createElement("div");
  const textColor = selected ? "text-green-500" : "text-blue-500";
  const bgColor = selected ? "bg-green-500" : "bg-blue-500";
  const borderColor = selected ? "border-green-500" : "border-blue-500";
  div.classList.add(
    "flex",
    "items-center",
    "text-blue-500",
    "font-sans",
    "text-xs",
    "font-medium",
  );

  div.innerHTML = `
    <div class="w-5 h-5 mr-2 [clip-path:polygon(50%_0%,_0%_100%,_100%_100%)] ${bgColor} ${borderColor}" style="transform: rotate(${heading}deg)"></div>
    <div class="bg-blue-100 p-2 border-2 rounded-lg ${borderColor} ${textColor}">${vessel_name}</div>
  `;
  return div;
}

function avatar(
  avatar: string | null,
  alt: string | null,
  initials: string | null,
) {
  if (avatar) {
    return `
      <img
        src=${avatar}
        class="w-10 h-10 rounded-full object-cover"
        alt=${alt || ""}
        loading="lazy"
      />
    `;
  } else {
    return `
      <div class="relative inline-flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-gray-100 dark:bg-gray-600">
        <span class="font-medium text-gray-600 dark:text-gray-300">${initials || ""}</span>
      </div>
    `;
  }
}

const VesselLocation = {
  mounted(this: any) {
    const loader = new GoogleMapsLoader({
      apiKey: this.el.dataset.apikey,
      version: "weekly",
    });

    this.map = null;
    this.markers = {};
    this.select = null;
    this.selectedLocation = null;
    this.selectedAddress = null;
    this.selectedCandidate = null;
    this.infoHeaderDiv = document.createElement("div");
    this.infoBodyDiv = document.createElement("div");

    this.infoHeaderDiv.classList.add("mt-10");

    this.createSearchControl = () => {
      const locationOptions = JSON.parse(this.el.dataset.locationOptions);

      // Create the control div
      const controlDiv = document.createElement("div");
      controlDiv.classList.add(
        "m-2",
        "p-5",
        "bg-white",
        "rounded-md",
        "shadow-md",
        "cursor-pointer",
      );

      // Create the search div
      const searchDiv = document.createElement("div");
      searchDiv.classList.add("bg-white");

      // Add a select element to the custom control
      const selectElement = document.createElement("select");
      selectElement.classList.add("w-72", "font-sans");

      searchDiv.appendChild(selectElement);

      // Add the custom control to the map
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(controlDiv);

      const options = locationOptions.map(({ id, title }: LocationOption) => ({
        id: id,
        title: title,
      }));

      // Initialize TomSelect on the select element
      this.select = new TomSelect(selectElement, {
        create: false,
        hideSelected: true,
        maxItems: 1,
        options: options,
        placeholder: "Search",
        valueField: "id",
        labelField: "title",
        searchField: ["title"],
        sortField: "title",
      });

      this.select.on("change", (value: any) => {
        this.select.blur();
        this.pushEvent("select_changed", { id: value });
      });

      searchDiv.appendChild(this.infoHeaderDiv);
      searchDiv.appendChild(this.infoBodyDiv);
      controlDiv.appendChild(searchDiv);
    };

    this.updateSearchControl = () => {
      const locationOptions = JSON.parse(this.el.dataset.locationOptions);

      if (this.select) {
        this.select.clearOptions();

        this.select.addOptions(locationOptions);
      }

      if (this.selectedCandidate) {
        let exCrew = "";

        if (this.selectedCandidate.ex_crew) {
          exCrew = `
            <div class="flex justify-center">
              <span class="px-4 py-2 text-sm font-medium text-green-700 bg-green-100 rounded-full">
                Ex-Crew
              </span>
            </div>
          `;
        }

        this.infoHeaderDiv.innerHTML = `
          <div class="w-full max-w-sm mx-auto bg-white rounded-lg shadow-md p-6 space-y-4">
            <div id="back-to-vessel-btn" class="text-blue-600 text-sm font-semibold cursor-pointer">
              &larr; Back to Vessel
            </div>

            <div class="flex flex-col items-center text-center space-y-2">
              ${avatar(this.selectedCandidate.avatar, this.selectedCandidate.full_name, this.selectedCandidate.initials)}
              <p class="text-lg font-bold text-gray-800">${this.selectedCandidate.full_name || ""}</p>
              <p class="text-sm font-semibold text-gray-500 uppercase">${this.selectedCandidate.rank || ""}</p>
            </div>

            <div class="text-sm space-y-1 text-gray-700">
              <p>Email: <span class="font-medium">${this.selectedCandidate.email || ""}</span></p>
              <p>Mobile: <span class="font-medium">${this.selectedCandidate.mobile_phone || ""}</span></p>
              <p>Home: <span class="font-medium">${this.selectedCandidate.home_phone || ""}</span></p>
              <p>Readiness: <span class="font-medium">${this.selectedCandidate.readiness_date || ""}</span></p>
              <p>Application: <span class="font-medium">${this.selectedCandidate.application_date || ""}</span></p>
            </div>

            ${exCrew}

          </div>
        `;

        const backBtn = document.getElementById("back-to-vessel-btn");

        if (backBtn) {
          backBtn.addEventListener("click", () => {
            this.selectedCandidate = null;
            this.updateSearchControl();
          });
        }

        const workHistories = this.selectedCandidate.work_histories
          .map((workHistory: WorkHistory) => {
            return `
            <div class="text-sm text-gray-700">
              <p class="font-semibold">${workHistory.company || ""}</p>
              <p class="text-gray-500">Rank: ${workHistory.rank || ""}</p>
              <p class="text-gray-500">Period: ${workHistory.start_at || ""} - ${workHistory.end_at || ""}</p>
            </div>
          `;
          })
          .join("");

        this.infoBodyDiv.innerHTML = `
          <div class="w-full max-w-sm mx-auto bg-white rounded-lg shadow-md p-6 space-y-4">
            <!-- Source and Positions -->
            <div class="space-y-4">
              <div>
                <p class="text-sm font-bold text-gray-800">Source</p>
                <p class="flex items-center space-x-2 text-sm text-gray-700">
                  ${avatar(this.selectedCandidate.source_logo, this.selectedCandidate.source_name, this.selectedCandidate.source_initials)}
                  <span>${this.selectedCandidate.source_name || ""}</span>
                </p>
              </div>

              <div>
                <p class="text-sm font-bold text-gray-800">Manning Agent</p>
                <p class="flex items-center space-x-2 text-sm text-gray-700">
                  ${avatar(this.selectedCandidate.manning_agent_logo, this.selectedCandidate.manning_agent_name, this.selectedCandidate.manning_agent_initials)}
                  <span>${this.selectedCandidate.manning_agent_name || ""}</span>
                </p>
              </div>

              <div>
                <p class="text-sm font-bold text-gray-800 mb-4">Last 5 Positions</p>
                <div class="flex items-start space-x-2">
                  ${workHistories}
                </div>
              </div>
            </div>
          </div>
      `;
      } else if (this.selectedLocation) {
        loader.importLibrary("geocoding").then(({ Geocoder }) => {
          const latlng = {
            lat: this.selectedLocation?.lat ?? 0,
            lng: this.selectedLocation?.lon ?? 0,
          };
          const geocoder = new Geocoder();

          geocoder
            .geocode({ location: latlng })
            .then((response) => {
              const { results } = response;
              if (Array.isArray(results)) {
                const result = results.at(-2) || results.at(-1);
                if (result) {
                  this.selectedAddress = result.formatted_address;
                }
              }
            })
            .catch((error) => {
              console.error("Geocoding failed:", error);
            });
        });

        let vesselPhoto = null;

        if (this.selectedLocation.vessel_photo) {
          vesselPhoto = `
            <img
              src=${this.selectedLocation.vessel_photo}
              class="w-96 h-60 rounded-lg my-5"
              alt=${this.selectedLocation.vessel_name}
              loading="lazy"
            />
          `;
        } else {
          vesselPhoto = `
            <div class="relative inline-flex h-60 w-96 items-center justify-center overflow-hidden rounded-lg bg-gray-100 dark:bg-gray-600 my-5">
              <span class="font-medium text-gray-600 dark:text-gray-300">${this.selectedLocation.vessel_initials}</span>
              </div>
          `;
        }

        this.infoHeaderDiv.innerHTML = `
          <div>
            <h3 class="m-0 text-3xl">${this.selectedLocation.vessel_name}</h3>
            <p class="m-0 text-xl text-gray-500">${this.selectedLocation.vessel_type}</p>
            ${vesselPhoto}
            <div class="flex items-center">
              <div class="bg-gray-200 rounded-full w-10 h-10 flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                </svg>
              </div>
              <div class="ml-4 text-gray-700">
                <h2 class="text-base font-medium">${this.selectedAddress || ""}</h2>
                <p class="text-sm text-gray-500">59.9330111743586, 30.291109084706473</p>
              </div>
            </div>
            <div class="flex items-center mt-5">
              <div class="bg-gray-200 rounded-full w-10 h-10 flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                </svg>
              </div>
              <div class="ml-4 text-gray-700">
                <p class="text-base font-medium">${this.selectedLocation.candidates.length} seafarers on board</p>
              </div>
            </div>
          </div>
        `;

        this.infoBodyDiv.innerHTML = `
          <div class="border-t border-gray-300 mt-10 pt-10 overflow-y-auto h-dvh">
            ${this.selectedLocation.candidates
              .map((candidate: Candidate) => {
                return `
              <div class="flex items-center space-x-4 p-4 candidate-list-item" data-candidate-id=${candidate.id}>
                ${avatar(candidate.avatar, candidate.full_name, candidate.initials)}
               <div>
                 <p class="font-semibold text-gray-500 uppercase">${candidate.rank || ""}</p>
                 <p class="font-bold text-gray-800">${candidate.full_name || ""}</p>
               </div>
              </div>
            `;
              })
              .join("")}
          </div>
        `;

        document.querySelectorAll(".candidate-list-item").forEach((el) => {
          el.addEventListener("click", () => {
            const candidate = this.selectedLocation.candidates.find(
              // @ts-expect-error
              (candidate: Candidate) => candidate.id == el.dataset.candidateId,
            );
            if (candidate) {
              this.selectedCandidate = candidate;
            } else {
              this.selectedCandidate = null;
            }

            this.updateSearchControl();
          });
        });
      } else {
        this.infoHeaderDiv.innerHTML = null;
        this.infoBodyDiv.innerHTML = null;
      }
    };

    this.updateMarkers = () => {
      const locations = JSON.parse(this.el.dataset.locations);

      loader.importLibrary("marker").then(({ AdvancedMarkerElement }) => {
        locations.forEach(
          ({ id, lat, lon, heading, vessel_name }: Location) => {
            let content;

            if (this.selectedLocation?.id == id) {
              content = createMarkerElement(id, vessel_name, heading, true);
            } else {
              content = createMarkerElement(id, vessel_name, heading, false);
            }

            const marker = new AdvancedMarkerElement({
              map: this.map,
              position: { lat: lat, lng: lon },
              title: vessel_name,
              content: content,
              gmpClickable: true,
            });

            this.markers[id] = marker;

            marker.addListener(
              "click",
              ({ domEvent }: google.maps.MapMouseEvent) => {
                this.selectedLocation = null;
                this.selectedAddress = null;
                this.selectedCandidate = null;
                this.pushEvent("select_changed", { id: id });
              },
            );
          },
        );
      });
    };

    this.handleLocationSelect = (location: Location) => {
      if (location.id) {
        this.selectedLocation = location;
      } else {
        this.selectedLocation = null;
      }

      this.markers = {};
      this.updated();

      if (this.selectedLocation) {
        this.select.addItem(this.selectedLocation.vessel_id, true);
        this.map.panTo({
          lat: this.selectedLocation.lat,
          lng: this.selectedLocation.lon,
        });
        this.map.setZoom(6);
      } else {
        this.map.panTo({ lat: 0, lng: 0 });
        this.map.setZoom(2);
      }
    };

    this.handleEvent("select_location", this.handleLocationSelect);

    this.handleEvent(
      "select_candidate",
      ({
        candidate,
        location,
      }: {
        candidate: Candidate;
        location: Location;
      }) => {
        this.selectedCandidate = candidate;
        this.handleLocationSelect(location);
        this.updateSearchControl();
      },
    );

    this.handleEvent(
      "location_options",
      ({ options }: { options: LocationOption[] }) => {
        this.updateSearchControl();
      },
    );

    loader.importLibrary("maps").then(({ Map }) => {
      this.map = new Map(this.el, {
        mapId: "FLEETMAP",
        cameraControl: false,
        center: { lat: 0, lng: 0 },
        zoom: 2,
        disableDefaultUI: true,
      });

      this.createSearchControl();
    });

    this.updateMarkers();
  },

  updated(this: any) {
    this.updateMarkers();
    this.updateSearchControl();
  },
};

export default VesselLocation;
